.slider {
    width: 100%;
    height: 40px;
    background-color: #fcf9f9;
    border-radius: 5px;
    display: flex;
  }

  .thumb {
    width: 150px;
    height: 40px;
    border-radius: 5px;
    background: #bfbfbf;
    color: #fcfbfb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
  }
