body {
  display: flex;
  flex: 1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex: 1;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{box-sizing: border-box;}

/* Smartphones (portrait) */
@media (max-width : 600px) {
  html {
    display: flex;
    width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
  }
}

/* Smartphones (portrait) */
@media (max-width : 950px) and (min-width: 601px) {
  html {
    display: flex;
    width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
  }
}

/* Desktop */
@media (min-width: 951px) {
  html {
    display: flex;
    height: 100vh;
    max-width: 100vw;
    min-width: 100vw;
  }
}
